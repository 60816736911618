import React from 'react';

// Dodane testowo slajdy z rozdziału pierwszego!!!
// TODO: zmienić na właściwe slajdy
import Slide1 from 'src/slides/desktop/hu/chapter_3/sub_4/slide1';
import WrapperMobile from 'src/slides/mobile/hu/chapter_3/sub_4/wrapperMobile';

import { isMobile } from 'react-device-detect';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

// import Slide
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Józef Piłsudski föderációs koncepciója | A varsói csata" description="Hogyan próbálta Piłsudski megalkotni a Tengerköz koncepciót Oroszországgal szemben." lang="hu" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Józef Piłsudski föderációs koncepciója | A varsói csata" description="Hogyan próbálta Piłsudski megalkotni a Tengerköz koncepciót Oroszországgal szemben." lang="hu" />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
